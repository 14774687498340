/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.row-enter {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}

.row-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 500ms, transform 500ms;
}

.row-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.row-exit-active {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
  transition: opacity 500ms, transform 500ms;
}

body {
  overflow-x: hidden;
}
