.video-preview {
    border-radius: 6px;
    overflow: hidden;
}

.meme-preview {
    border-radius: 6px;
    overflow: hidden;
}

div:has( > .meme-title) {
    height: 100%;
    width: 100%;
}

div:has(.create-option-desc) {
    width: 100%;
}

.fav-fill path {
    fill: #a6025c !important;
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -0px; /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column:not(:first-child) {
    padding-left: 12px; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 12px;
}